import { forwardRef, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import type { LocationOption } from "@/react/helpers/mapboxHelper";
import { useMapbox } from "@/react/hooks/useMapbox";
import type { FormInputProps } from "@circle-react-uikit/Form/FormInput";
import { TextSelect } from "../../../TextSelect";

export const LocationInput = forwardRef<HTMLInputElement, FormInputProps>(
  ({ name }) => {
    const {
      locationOptions,
      handleSuggestionSelect,
      selectedMapboxId,
      debouncedSearch,
      selectedLocation,
    } = useMapbox();

    const { setValue } = useFormContext();

    useEffect(() => {
      setValue(name, selectedLocation);
    }, [selectedLocation, name, setValue]);

    return (
      <TextSelect
        placeholder={t("filters.location_filter.search_location")}
        emptyMessage={t("filters.location_filter.no_locations_found")}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any -- needs more ts conversions
        options={locationOptions as any}
        onChange={handleSuggestionSelect}
        onSearchChange={debouncedSearch}
        value={selectedMapboxId}
        displayValue={(option: LocationOption) => option?.label || ""}
      />
    );
  },
);

LocationInput.displayName = "LocationInput";
